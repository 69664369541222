// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Topic, Lesson } = initSchema(schema);

export {
  Topic,
  Lesson
};