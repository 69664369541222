/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://ir5gdenrwzdsrnwcim6fbyx36m.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:6f5c43be-72f2-41d9-90c5-ab29401e75dd",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_x8KLj3NhN",
    "aws_user_pools_web_client_id": "t99pu33t5um76cel9un4ubvi3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lessons4e31b795ff1247a9ace726f0336ff6b8223709-fj",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
