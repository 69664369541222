import React, { useState, useEffect } from 'react';
import replaceAsync from "string-replace-async";
import ReactMarkdown from 'react-markdown'

import { Amplify, Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const REGEXP_LINK = /\[([^[]+)\]\(([^)]+)\)/g;

async function regenerateUrlsPromise(match, alt, uri) {
  const url = new URL(uri);
  const splitPath = url.pathname.split('/', 3);
  if (splitPath.length < 3)
    return match;
  const level = splitPath[1];
  let key = splitPath[2];
  let storageUri = '';
  switch (level) {
    case 'protected':
    case 'private': {
      const splitKey = key.split('/', 1)
      if (splitKey.length < 2)
        return match;
      const identityId = splitKey[0];
      key = splitKey[1];
      storageUri = await Storage.get(key, { level: level, identityId: identityId });
      break;
    }
    case 'public': {
      storageUri = await Storage.get(key, { level: level });
      break;
    }
    default: return match;
  }
  if (!storageUri)
    return match;
  const storageUrl = new URL(storageUri)
  if (url.hostname === storageUrl.hostname)
    return match.replace(uri, storageUri);
  else return match
}

export function ReactMarkdownRegenerateUrls(props) {
  const [resultMarkdown, setResultMarkdown] = useState('');
  useEffect(() => {
    replaceAsync(props.children, REGEXP_LINK, regenerateUrlsPromise)
      .then(newMarkdown => setResultMarkdown(newMarkdown));
  });

  return resultMarkdown && (
    <ReactMarkdown {...props} children={resultMarkdown} />
  );
}
