import React, { useState, useEffect } from 'react';
import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import { Lesson } from './models';
import { View, SelectField, Heading, Expander, ExpanderItem, Text, Placeholder } from '@aws-amplify/ui-react';

import { ReactMarkdownRegenerateUrls } from './ReactMarkdownRegenerateUrls';

export default function LessonsForm() {
  const [lessons, setLessons] = useState(null);
  const [loading, setLoading] = useState(true)
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentTopics, setCurrentTopics] = useState(null);

  useEffect(() => {
    DataStore.observeQuery(Lesson, Predicates.ALL, { sort: l => l.order(SortDirection.ASCENDING) })
      .subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        setLessons(items);
        setLoading(!isSynced)
      })
  }, []
  )

  if (loading) {
    return (
      <View padding="1rem" spacing="1rem">
          <Heading>
            Loading...
          </Heading>
          <Placeholder />;
      </View>
    )
  }

  return (
    lessons &&
    <View>
      <SelectField
        placeholder="Choose a lesson from the list"
        label="Choose a lesson"
        labelHidden={true}
        variation="quiet"
        onChange={async (e) => {
          const foundLesson = lessons.find(item => item.id === e.target.value)
          if (foundLesson) {
            const foundTopics = await foundLesson.Topics.toArray();
            setCurrentLesson(
              foundLesson
            )
            setCurrentTopics(
              foundTopics.sort((a, b) => (a.order > b.order) ? 1 : -1)
            )
          }
          else {
            setCurrentLesson(null);
            setCurrentTopics(null);
          }
        }}>
        {lessons.map((item) => (
          <option value={item.id}>
            {item.title}
          </option>
        ))}
      </SelectField>
      {currentLesson && currentTopics ? (
        <Text>
          <ReactMarkdownRegenerateUrls children={currentLesson.description} />
          <Expander type="multiple">
            {
              currentTopics.map((item) => (
                <ExpanderItem title={item.title} value={item.id}>
                  <ReactMarkdownRegenerateUrls children={item.doc} />
                </ExpanderItem>
              ))
            }
          </Expander>
        </Text>
      ) : (
        <Heading paddingTop="3rem" paddingBottom="3rem">
          We hope you find our content engaging!
        </Heading>
      )}
    </View>
  )
}
