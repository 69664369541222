import { DataStore, Hub, Logger } from 'aws-amplify';
import { defaultDarkModeOverride, ThemeProvider, Authenticator, View, Card, Flex, Heading, Divider, Menu, MenuItem } from '@aws-amplify/ui-react';
import LessonsForm from './LessonsForm';

import './App.css'

const logger = new Logger('app')

Hub.listen('auth', async (hubData) => {
  const { event, data } = hubData.payload;
  logger.info('Auth event', event, data);
  if (event === 'signOut') {
    logger.info('Clearing local datastore on sign out');
    await DataStore.clear();
  }
});

export default function App() {
  const theme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride],
  };

  return (
    <ThemeProvider theme={theme} colorMode="system">
      <View width="100%">
        <Card>
          <Authenticator hideSignUp={true}>
            {({ signOut, user }) => (
              <View>
                <Flex justifyContent="space-between">
                  <Heading level="3">
                    Our lessons
                  </Heading>
                  <Flex justifyContent="flex-end" alignItems="center" alignContent="center" textAlign="right">
                    <Menu menuAlign="end" >
                      <MenuItem onClick={signOut}>
                        Sign out {user.attributes.email}
                      </MenuItem>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider />
                <LessonsForm />
              </View>
            )}
          </Authenticator>
        </Card>
      </View>
    </ThemeProvider>
  )
}
